import './feature-list.scss';
import React from 'react';
import { ContactUxology } from './contact-uxology';

export const FeatureList = (props) => (
	<div className="features padding-top-sm-10 row">
		{props.items.map(({ img, title, description }, index) => (
			<>
				{(index === 3 || index === 6) && (
					<ContactUxology
						withSameSize
						page="services"
						className="services-section-item col-sm-4"
						topLabel="Questions ?"
						bottomLabel="Contact Us"
					/>
				)}
				<div
					className={`feature-item col-sm-4 ${
						index === props.items.length - 1 && props.items.length % 3 === 1
							? 'offset-sm-4'
							: ''
					}`}
				>
					<div className="feature-item-icon">{img}</div>
					<h4 className="services-item-title font-second">{title}</h4>
					<div className="feature-item-text">
						<p>{description}</p>
					</div>
				</div>
			</>
		))}
	</div>
);
