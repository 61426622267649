/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useRef } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
	createMuiTheme,
	useTheme,
	ThemeProvider,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormLabel from '@material-ui/core/FormLabel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonBase from '@material-ui/core/ButtonBase';
import { CircularProgress } from '@material-ui/core';
import Layout, { PageColors, PageTextColors } from '../components/layout';
import { HeaderBetweenLogos } from '../components/header-between-logos';
import * as styles from './coaching.module.scss';
import Footer from '../components/footer';

import ContactWidget, { functionsUrlRoot } from '../components/contact-widget';

import uxgSlug from '../images/uxg-slug.png';
import coachingContactLogo from '../images/coaching-contact-logo.png';
import uxologyGroupLogo from '../images/uxology-group-logo.png';
import { FeatureList } from '../components/feature-list';
import { HeroSection } from '../components/hero-section';

const theme = createMuiTheme({
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: '#36da0a',
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		// secondary: {
		// 	light: '#0066ff',
		// 	main: '#0044ff',
		// 	// dark: will be calculated from palette.secondary.main,
		// 	contrastText: '#ffcc00',
		// },
		// // Used by `getContrastText()` to maximize the contrast between
		// // the background and the text.
		// contrastThreshold: 3,
		// // Used by the functions below to shift a color's luminance by approximately
		// // two indexes within its tonal palette.
		// // E.g., shift from Red 500 to Red 300 or Red 700.
		// tonalOffset: 0.2,
	},
});

function SignupDialog({ open, onClose }) {
	const themeData = useTheme();
	const fullScreen = useMediaQuery(themeData.breakpoints.down('sm'));

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	const formRef = useRef();
	const [loading, setLoading] = useState();

	const handleSubmit = async () => {
		const formData = new FormData(formRef.current);
		console.log(`form data:`, formData);
		setLoading(true);
		const res = await axios.post(
			`${functionsUrlRoot}/coaching-signup`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);
		console.log(`response:`, res);
		setLoading(false);
		handleClose();
		// Use timeout to let DOM hide dialog before showing alert
		setTimeout(() => {
			window.alert(`Thanks, form submitted, we'll be in touch!`);
		}, 150);
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			className={styles.signupDialog}
			maxWidth="md"
		>
			<DialogTitle id="form-dialog-title">
				Sign Up for UX Career Coaching
			</DialogTitle>
			<DialogContent>
				<div className={styles.intro}>
					Want to schedule a coaching session with us? We just need a few
					details first.
				</div>
				<form ref={formRef}>
					<TextField
						autoFocus
						name="name"
						label="Name"
						type="string"
						variant="outlined"
						fullWidth
						required
					/>
					<TextField
						name="email"
						label="Email Address"
						type="email"
						variant="outlined"
						fullWidth
						required
					/>
					<TextField
						autoFocus
						name="linkedInUrl"
						label="LinkedIn Profile URL"
						type="url"
						variant="outlined"
						fullWidth
					/>
					{/* <TextField
						autoFocus
						name="websiteUrl"
						label="Website URL"
						type="url"
						variant="outlined"
						fullWidth
					/> */}

					<input
						accept="*/*"
						style={{ display: 'none' }}
						id="signup-file-upload-button"
						multiple
						type="file"
						name="file"
					/>
					<FormLabel
						htmlFor="signup-file-upload-button"
						className={styles.uploadLabel}
					>
						Additional files you'd like to share
						<br />
						<ButtonBase
							variant="raised"
							component="div"
							className={styles.uploadButton}
						>
							<InsertDriveFileIcon />
							<span>Upload files (resume, portfolio, etc.)</span>
						</ButtonBase>
					</FormLabel>

					<TextField
						name="notes"
						label="What's going on / how can we help?"
						variant="outlined"
						fullWidth
						multiline
						rowsMax={4}
						required
					/>
				</form>
			</DialogContent>
			<div className={styles.ctaFooter}>
				{loading ? (
					<center>
						<CircularProgress />
					</center>
				) : (
					<>
						<Button onClick={handleSubmit} className={styles.cta}>
							Send
						</Button>
						<p>
							<b>After you hit "Send":</b>
							We'll review your information and get in touch within 24 hours to
							talk through your situation and schedule your first session.
						</p>
					</>
				)}
			</div>
		</Dialog>
	);
}

export default function AboutPage(props) {
	const packageMap = [
		{
			label: 'Intro Session',
			a: true,
			c: true,
			hint: `We'll chat about your goals and the state of your materials (resume, portfolio, etc.)`,
		},
		{
			label: 'Resume Help',
			a: true,
			c: true,
			hint: `We'll work with you to create an impactful resume that can be read by humans and applicant tracking systems.`,
		},
		{
			label: 'Cover Letter Assistance',
			a: true,
			c: true,
			hint: `We'll help you write a cover letter that sets you apart from other candidates.`,
		},
		{
			label: 'LinkedIn Audit',
			a: true,
			c: true,
			hint: `We'll help you create a LinkedIn profile that helps you connect with a fellow UXers and get noticed by recruiters`,
		},
		{
			label: 'Portfolio & case study review',
			a: true,
			c: true,
			hint: `We'll work with you on your case studies and how to present them to hiring managers and potential peers`,
		},
		{
			label: 'Candidate journey overview',
			b: true,
			c: true,
			hint: `We'll take the mystery out of how the UX hiring process works`,
		},
		{
			label: 'Interview preparation',
			b: true,
			c: true,
			hint: `We'll advise you on how to answer everything from "Tell me about yourself" to "Tell me about a time when..."`,
		},
		{
			label: '1:1 practice interviews',
			b: true,
			c: true,
			hint: `We'll practice interview Q&A portfolio presentations, and design exercises`,
		},
		{
			label: 'Mock group interviews',
			b: true,
			c: true,
			hint: `We'll act as a panel of interviewers and get you ready for the real deal`,
		},
		{
			label: 'Job search strategizing',
			c: true,
			hint: `We'll help you figure out what roles are great matches for you and how to go about getting them`,
		},
		{
			label: 'Compensation negotiation',
			c: true,
			hint: `We'll teach you how to negotiate for a great compensation package`,
		},
		{
			label: 'How to start your new UX job most successfully',
			c: true,
			hint: `We'll get you off to a great start in your new role`,
		},
	];

	const SafeExtLink = ({ href, children }) => (
		<a href={href} rel="noopener noreferrer" target="_blank">
			{children}
		</a>
	);

	const [signupOpen, setSignupOpen] = useState(false);
	const openSignup = () => setSignupOpen(true);
	const closeSignup = () => setSignupOpen(false);

	const SignupButton = ({ children }) => (
		<ButtonBase className={styles.cta} onClick={openSignup}>
			{children}
		</ButtonBase>
	);

	return (
		<ThemeProvider theme={theme}>
			<Layout page="coaching" location={props.location}>
				<SignupDialog open={signupOpen} onClose={closeSignup} />
				<div className={styles.root}>
					<HeroSection
						// title="UX CAREER COACHING"
						words={['UX', 'CAREER', 'COACHING']}
						bgColor={PageColors.coaching}
						textColor={PageTextColors.coaching}
					>
						WE HELP EVERYONE FROM ASPIRING UXers TO SEASONED PRACTITIONERS
						NAVIGATE THEIR CAREERS
					</HeroSection>

					<section className={clsx(styles.sub, styles.ctaSection)}>
						<img
							className={styles.headerLogo}
							src={uxologyGroupLogo}
							alt="UXology logo"
						/>
						<h1>OUR COACHING SERVICES</h1>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="Where Are You in Your UX Career Journey?"
						/>
						<div className={styles.content}>
							<ul className={styles.personas}>
								<li>
									<StaticImage
										src="../images/coaching/persona1_Starting-Out.jpg"
										alt="Starting Out"
									/>
									<h4>Starting Out</h4>
									<p>
										Did you recently complete a UX bootcamp or UX-related
										degree? Need help landing your first UX job?
									</p>
								</li>
								<li>
									<StaticImage
										src="../images/coaching/persona2_Moving-Up.jpg"
										alt="Moving Up"
									/>
									<h4>Moving Up</h4>
									<p>
										Want to advance but second-guessing your UX skills and
										process?
									</p>
								</li>
								<li>
									<StaticImage
										src="../images/coaching/persona3_Pivoting.jpg"
										alt="Pivoting"
									/>
									<h4>Pivoting</h4>
									<p>
										Are you pivoting into UX from another field? Need help
										reframing your experience and identifying gaps?
									</p>
								</li>
								<li>
									<StaticImage
										src="../images/coaching/persona4_Impostering.jpg"
										alt="Impostering"
									/>
									<h4>Impostering</h4>
									<p>
										Struggling to feel like the expert you are? Wondering if
										you're even hireable?
									</p>
								</li>
								<li>
									<StaticImage
										src="../images/coaching/persona5_Feeling-Stuck.jpg"
										alt="Feeling Stuck"
									/>
									<h4>Feeling Stuck</h4>
									<p>
										Looking for a more rewarding role? Need help curating your
										work and telling your story?
									</p>
								</li>
								<li>
									<StaticImage
										src="../images/coaching/persona6_Open-to-Work.jpg"
										alt="Open to Work"
									/>
									<h4>Open to Work</h4>
									<p>
										Were you recently let go and need to find a new UX job
										quickly?
									</p>
								</li>
								<li>
									<StaticImage
										src="../images/coaching/persona7_Interviewing.jpg"
										alt="Interviewing"
									/>
									<h4>Interviewing?</h4>
									<p>Need help with interview prep ASAP?</p>
								</li>
							</ul>
							<div className={styles.ctaWrap}>
								<h2>We can help!</h2>

								<p>
									<img
										className={styles.inlineLogo}
										src={uxologyGroupLogo}
										alt="UXology logo"
									/>
									For most of us, finding a new job isn't something we do on a
									regular basis and it can be an intimidating process. We can
									demystify this process for you. We are principal-level UX
									practitioners with years of experience interviewing candidates
									for UX Researcher, UX/Product Designer, and UX Manager roles
									(and being interviewed ourselves.)
								</p>

								<p>
									<img
										className={styles.inlineLogo}
										src={uxologyGroupLogo}
										alt="UXology logo"
									/>
									We can help you prioritize what you need to do, plus give you
									some structure and accountability. We'll also give you very
									honest (but kind, and funny where warranted!) feedback on what
									you need to work on. We will challenge you and champion you.
								</p>

								<SignupButton>Schedule a session</SignupButton>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.dataSection)}>
						<div className={styles.content}>
							<div className={styles.dataWrap}>
								<h1>Data For All the UX Nerds</h1>

								<StaticImage
									className={styles.heroSvg}
									src="../images/coaching/illustration1_Data-for-all-the-UX-Nerds.svg"
									alt="Illustration of people looking at a chart"
								/>

								<ul>
									<li>
										<img
											src={uxgSlug}
											alt="UXG Slug"
											className={styles.chartIcon}
										/>
										<p>
											Did you know there are currently over 20k open UX jobs in
											the USA?
											<br />(
											<SafeExtLink href="https://indeed.com">
												Indeed
											</SafeExtLink>{' '}
											and{' '}
											<SafeExtLink href="https://glassdoor.com">
												Glassdoor
											</SafeExtLink>
											, 2021)
										</p>
									</li>
									<li>
										<img
											src={uxgSlug}
											alt="UXG Slug"
											className={styles.chartIcon}
										/>
										<p>
											Did you know the average amount of time to find a job is
											24 weeks?
											<br />(
											<SafeExtLink href="https://www.bls.gov/news.release/pdf/empsit.pdf">
												Bureau of Labor Statistics
											</SafeExtLink>
											, 2020)
										</p>
									</li>
									<li>
										<img
											src={uxgSlug}
											alt="UXG Slug"
											className={styles.chartIcon}
										/>
										<p>
											Did you know most candidate journeys last an average of 35
											- 41 days?
											<br />(
											<SafeExtLink href="https://www.statista.com/statistics/881023/recruitment-average-time-to-hire-by-company-size-worldwide/">
												Statista
											</SafeExtLink>
											, 2018)
										</p>
									</li>
									<li>
										<img
											src={uxgSlug}
											alt="UXG Slug"
											className={styles.chartIcon}
										/>
										<p>
											On average, each corporate job offer attracts 250 resumes.
											Of those candidates, 4 to 6 will get called for an
											interview, and only one will get the job.
											<br />(
											<SafeExtLink href="https://zety.com/blog/hr-statistics">
												Zety & Glassdoor
											</SafeExtLink>
											, 2021)
										</p>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.coachSection)}>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="About Our UX Career Coaches"
						/>
						<div className={styles.content}>
							<StaticImage
								className={styles.heroImg}
								src="../images/coaching/photo_Lisa-Murnan-coaching-page.jpg"
								alt="Photo of Lisa Murnan"
							/>

							<div className={styles.bioPanel}>
								<h2>Lisa Murnan</h2>

								<p>
									UXG Coaching Director, Lisa Murnan, is a Sr. Principal Product
									Designer and the author of{' '}
									<SafeExtLink href="https://www.amazon.com/How-Get-Design-Job-application/dp/0988630427">
										How to Get a UX Design Job
									</SafeExtLink>
									.
								</p>

								<p>
									As an integral part of UXG, Lisa works 1:1 with clients and
									develops and leads our coaching program, including coaching
									the coaches.
								</p>

								<p>
									<b>
										Our team will match you up to the right coach for your
										situation. Just fill out our (very short) form and we'll
										quickly follow up.
									</b>
								</p>

								<SignupButton>Schedule a session</SignupButton>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.successStories)}>
						<div className={styles.content}>
							<div className={styles.dataWrap}>
								<h1>Success Stories</h1>

								<ul>
									<li className={styles.heroCell}>
										<StaticImage
											className={styles.heroSvg}
											src="../images/coaching/illustration2_Success-Stories.svg"
											alt="Illustration of a person standing next to a story board"
										/>
									</li>
									<li>
										<p>
											I applied to 300+ jobs and wouldn't get past the first
											round of interviews. After working with Lisa on my resume
											and case studies, I accepted a job offer within a month
											and turned another down!
										</p>
										<p>
											Lisa is amazing at what she does and gave me honest
											feedback while also giving me the reassurance that I
											wasn't crazy trying to make a transition in a pandemic!
										</p>
										<p className={styles.attrib}>
											<b>Alicen</b>
											<br />
											UX Designer
										</p>
									</li>
									<li>
										<p>
											Super happy to share that this evening I received the
											written offer for [company] as an Experience Designer! And
											with the higher end of the salary, which I discussed with
											the hiring manager :-).
										</p>
										<p>
											I so much appreciate your support and guidance along the
											way, which helped me gain confidence and put my best foot
											forward. I am excited for this next professional chapter,
											and am so grateful for your role along my journey.
										</p>
										<p className={styles.attrib}>
											<b>Hannah</b>
											<br />
											Experience Designer
										</p>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.specializedSection)}>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="Specialized Coaching Sessions"
						/>
						<div className={styles.content}>
							<div className={styles.dataWrap}>
								<p>
									We call ourselves "career coaches" but we also serve you as
									strategists, experts, mentors, teachers, and sometimes even UX
									therapists. our goal is to get you your results quickly.
								</p>

								<p>
									Our coaching sessions are typically an hour long and are
									customized for you. We can cover any of the offerings below
									(plus anything else career/UX-related you want to throw at us.
									Toxic boss? Been there~ Ageism? Yup.)
								</p>

								<p>
									We don't require you to sign up for coaching packages
									(although if a package is what you're looking for, scroll
									down!
								</p>

								<p>Here are some common requests/questions we get:</p>

								<ul>
									<img
										className={styles.backgroundLogo}
										src={uxologyGroupLogo}
										alt="UXology logo"
									/>
									<li>
										I've had 24 interviews with 10 companies and I haven't been
										offered a position with any of them. Can you help me figure
										out what I'm doing wrong?
									</li>
									<li>
										I could use some help working on my portfolio ... I'm
										finding it difficult to talk about my experience because it
										feels so specialized!
									</li>
									<li>
										I'm planning to study UX design and was wondering if you
										would advise me through my choices and ultimately my job
										hunt.
									</li>
									<li>
										I need feedback on my portfolio and my resume and preparing
										for an upcoming interview.
									</li>
									<li>
										I am currently a Product Manager and would love to
										transition into a full-time UX Researcher role.
									</li>
									<li>
										I shifted careers last year and finished a UX bootcamp.
										Since then, I've applied at over 100 jobs and gotten zero
										interviews. I'd love to figure out what I can do to get
										noticed.
									</li>
									<li>
										I have an interview next Thursday. I know it's short notice,
										but was wondering whether you're available for a mock
										interview anytime before then?
									</li>
									<li>
										Is it possible to get a UX coaching session for my son for
										his birthday?
									</li>
									<li>
										I'm wondering at this point if I'm even hirable anymore.
										Have you ever helped somebody in this situation? Short
										answer: Yes!
									</li>
								</ul>

								<div className={styles.pricing}>
									<div>$175 each</div>
									<div>$95 for follow-up sessions</div>
								</div>

								<SignupButton>Schedule a session</SignupButton>

								<FeatureList
									items={[
										{
											img: (
												<StaticImage
													src="../images/coaching/resume.png"
													alt="Icon"
												/>
											),
											title: 'Resume Help',
											description: `Your resume often forms the first and most important ongoing impression to potential employers. Start your job search with the confidence of knowing it’s representing you in the best way possible!`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/cover-letter.png"
													alt="Icon"
												/>
											),
											title: 'Cover Letter Assistance',
											description: `Contrary to popular belief, cover letters are not a thing of the past. In fact, they are one of the most overlooked tactics to ensure that you stand out from the hundreds of other applicants.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/portfolio.png"
													alt="Icon"
												/>
											),
											title: 'Portfolio Review',
											description: `Much has been published about how to create outstanding UX portfolios. However, many of the best kept secrets can only be unlocked through an independent and iterative review of your portfolio.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/job-search.png"
													alt="Icon"
												/>
											),
											title: 'Job Search Strategizing',
											description: `One of the most common mistakes we see in job seekers is not thinking through their search strategically. Creating a well thought out action plan saves time and drastically increases your likelihood of success.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/audit.png"
													alt="Icon"
												/>
											),
											title: 'Linkedin Audit',
											description: `LinkedIn profiles help to form and bolster ongoing impressions of your professional posture. Our independent audits will help ensure yours is projecting the desired perception in line with the rest of your online presence.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/meeting.png"
													alt="Icon"
												/>
											),
											title: 'Interview Preparation',
											description: `Getting through the proverbial door is one thing, but convincing a series of gatekeepers and decision makers to hire you is quite another! From knowing how UX interviews typically work, to anticipating common questions and preparing for each step along the way, permits us to help you through the most critical moments of your journey.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/interview.png"
													alt="Icon"
												/>
											),
											title: 'Mock Interview',
											description: `What’s the best and most time-tested way to prepare for anything? Practice. Take advantage of this no-nonsense wisdom by setting up mock interviews with our coaches, each of whom have interviewed a multitude of UXers.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/graphic-design.png"
													alt="Icon"
												/>
											),
											title: 'Mock Design Exercise',
											description: `Interviews are all talk, but design and research exercises are opportunities to demonstrate that you can walk the walk. When employers challenge you in this manner, it’s wise to have faced these challenges before in low risk (coaching) sessions.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/profit.png"
													alt="Icon"
												/>
											),
											title: 'Compensation Negotiation',
											description: `Most UXers are uncomfortable talking about compensation, which is very unfortunate, given that it’s the entire reason for work in the first place! Don’t make the common mistake of fumbling at the finish line; our coaches will help you negotiate like a champion, to obtain your true market worth.`,
										},
										{
											img: (
												<StaticImage
													src="../images/coaching/qa.png"
													alt="Icon"
												/>
											),
											title: 'Open Q&A',
											description: `Do you have career-related UX questions that aren’t covered in the above scenarios? No problem! Just let us know what you’d like to discuss and we’ll be happy to accommodate.`,
										},
									]}
								/>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.supportSection)}>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="On-Demand Support"
							subTitle="Throughout Your Job Search"
						/>
						<div className={styles.content}>
							<div className={styles.dataWrap}>
								<p>
									Sometimes it makes the most sense to keep things simple. This
									option is for you if you want our experts in your corner 100%
									of the time throughout key phases of your job search.
								</p>

								<p>It includes the following:</p>
								<ul className={styles.imageBullet}>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Round-the-clock access to our entire coaching team
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Guaranteed same business day response time
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Virtually instant answers to ANY career-related questions
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										On-demand feedback for ANY candidate materials (resume,
										cover letter, portfolio, case studies, etc.
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Last-minute pep talks and targeted prep for important
										interviews, presentations, etc.
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										No-fuss scheduling of calls to talk through any
										career-related topic of your choosing
									</li>
								</ul>

								<p className={styles.pricing}>
									<b>$500 per week or $1500 per month</b>
								</p>

								<SignupButton>Schedule a session</SignupButton>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.packagesSection)}>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="Featured Coaching Packages"
						/>
						<div className={styles.content}>
							<StaticImage
								className={styles.heroSvg}
								src="../images/coaching/illustration3_Featured-Coaching-Packages.svg"
								alt="Icon"
							/>

							<div className={styles.tableWrap}>
								<table>
									<thead>
										<tr>
											<th>&nbsp;</th>
											<th>
												<label>
													A) Optimizing Candidate Materials
													<br />
													$495
												</label>
												<SignupButton>Sign Up</SignupButton>
											</th>
											<th>
												<label>
													B) Acing Your Interviews
													<br />
													$999
												</label>
												<SignupButton>Sign Up</SignupButton>
											</th>
											<th>
												<label>
													C) End-to-End Job Readiness Program
													<br />
													Contact for Pricing
												</label>
												<SignupButton>Sign Up</SignupButton>
											</th>
										</tr>
									</thead>
									<tbody>
										{packageMap.map(({ label, hint, a, b, c }) => (
											<tr key={label}>
												<Tooltip title={hint || label}>
													<td className={styles.label}>{label}</td>
												</Tooltip>
												<td className={clsx(styles.box, a && styles.checked)}>
													{a && <div className={styles.check} />}
												</td>
												<td className={clsx(styles.box, b && styles.checked)}>
													{b && <div className={styles.check} />}
												</td>
												<td className={clsx(styles.box, c && styles.checked)}>
													{c && <div className={styles.check} />}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</section>

					<section className={clsx(styles.sub, styles.howSection)}>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="How This Works"
						/>
						<div className={styles.content}>
							<div className={styles.dataWrap}>
								<div className={styles.ulWrap}>
									<ul>
										<li>
											<div className={styles.imageWrap}>
												<StaticImage
													src="../images/coaching/illustration4-1_Sign-Up-for-a-Session-or-Package.svg"
													alt="Illustration of a person in front of a door"
												/>
											</div>
											<div className={styles.number}>1.</div>
											<div className={styles.deets}>
												<h3>Sign Up For a Session or Package</h3>
												<p>
													After you apply we'll pair you up with a world class
													coach that fits your needs & personality.
												</p>
											</div>
										</li>
										<li>
											<div className={styles.imageWrap}>
												<StaticImage
													src="../images/coaching/illustration4-2_Prepare-for-Session.svg"
													alt="Illustration of a person sitting"
												/>
											</div>
											<div className={styles.number}>2.</div>
											<div className={styles.deets}>
												<h3>Prepare for Sessions</h3>
											</div>
										</li>
										<li>
											<div className={styles.imageWrap}>
												<StaticImage
													src="../images/coaching/illustration4-3_Attend-coaching-session.svg"
													alt="Illustration of a people on a video conference"
												/>
											</div>
											<div className={styles.number}>3.</div>
											<div className={styles.deets}>
												<h3>Attend Coaching Sessions</h3>
											</div>
										</li>
										<li>
											<div className={styles.imageWrap}>
												<StaticImage
													src="../images/coaching/illustration4-4_Act-on-our-Recommendations.svg"
													alt="Illustration of a person working at a desk"
												/>
											</div>
											<div className={styles.number}>4.</div>
											<div className={styles.deets}>
												<h3>Act On Our Recommendations</h3>
											</div>
										</li>
										<li>
											<div className={styles.imageWrap}>
												<StaticImage
													src="../images/coaching/illustration4_5_Experience-your-new-success.svg"
													alt="Illustration of a hero wearing a cape"
												/>
											</div>
											<div className={styles.number}>5.</div>
											<div className={styles.deets}>
												<h3>Experience Your New Success!</h3>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
					<div className={styles.logoAboveContact}>
						<img src={coachingContactLogo} alt="UXG Logo" />
					</div>
					<ContactWidget showSocial={true} />

					<Footer page="coaching" location={props.location} />
				</div>
			</Layout>
		</ThemeProvider>
	);
}
